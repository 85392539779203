export default {
  heading: "Terms & Conditions",
  list: [
    {
      title: "Who we are",
      html: `sofort24kredit.de’s loan matching service matches consumers with prospective lenders participating in sofort24kredit.de direct network of loan lenders (“participating lenders”). To use the service, you must complete one or more online forms that request specific information from you, including, but not limited to, your name, address, telephone number, email address, social security number, banking account information, and employment information. sofort24kredit.de use of this information is subject to this site’s Privacy Policy. In order to use this website or the service, you must agree to the separate terms.html set forth in the Privacy Policy, which are incorporated herein by reference.
      <br/><br/>
      By submitting the form(s), you understand and agree that you are submitting an inquiry about a loan product to sofort24kredit.de and each of the participating lenders to whom sofort24kredit.de transmits your loan request. By submitting the form(s), you are extending an express invitation to sofort24kredit.de to any and each of the participating lenders with whom your information is shared to contact you by any means (including, without limitation, email, telephone, and direct mail) so that they may assist you with your transaction or provide information to you about additional offers in which you may be interested.
      <br/><br/>
      Participating lenders who receive your information from sofort24kredit.de review your information in real-time to determine whether your information meets the lender’s loan qualification criteria. Participating lenders may, among other things, verify your social security number, perform a credit check, and review your information against national databases (such as Teletrack or DP Bureau) that tracks consumer transactions with lending institutions. By submitting the form(s), you agree to allow participating lenders to review, verify, and research your information in such manner.
      <br/><br/>
      If a participating lender determines that your information meets its loan qualification criteria, the sofort24kredit.de website will open a new web page identifying the name(s) and contact information of the lender(s), or redirect your browser to the lender’s website. You understand and agree that, once you have been redirected to the lender’s website, sofort24kredit.de has no further involvement in the loan application or approval process. Your subsequent interactions with the lender are subject to Privacy Policy, terms of use, and other policies and/or terms enforced by that lender.
      <br/><br/>
      sofort24kredit.de is not a lender and does not make loans or credit decisions in connection with its loan matching service. sofort24kredit.de does not guarantee acceptance into any particular loan program or specific loan terms of conditions with any participating lender. sofort24kredit.de does not guarantee that the price, product, availability, rates, fees, or any other loan terms offered and made available by participating lenders through the loan matching service are the best terms available in the market.
      <br/><br/>
      sofort24kredit.de does not endorse or recommend the products of any particular lender. sofort24kredit.de is not an agent of you or any participating lender. sofort24kredit.de is not involved with the lenders use and/or review of your information or in making a determination about whether you meet a particular lenders loan qualification criteria. The lender is solely responsible for its services to you, and you agree that sofort24kredit.de shall not be liable for any damages or costs of any type arising out of or in any way connected with your use of the loan matching service. You understand that participating lenders may keep your information, whether or not you are qualified for a loan with them.`,
    },
    {
      title: "Your acceptance of these terms.html",
      html: `By visiting our website and using this service, you are accepting our terms.html, privacy policy and cookie policy and agree to abide by them.
      <br/><br/>
      If you do not agree to these terms.html, you should stop using and/or accessing our website immediately. If you breach any of these terms, your right to access and use our website will cease immediately.
      <br/><br/>
      We acknowledge our obligations to this agreement by performing the service you have requested. In doing so, we are the service provider and you are our customer.
      <br/><br/>
      We deal directly with you and have no obligation to any party other than you, our customer.
      <br/><br/>
      If you are accessing our service on behalf of someone else, our obligation is still with you, the customer. As our customer, the terms.html of this agreement apply to you, not with any other parties or agents that may be connected to you.`,
    },
    {
      title: "Changes to these terms",
      html: `We reserve the right to amend these terms.html from time to time by amending this page. The amended terms.html will be effective from the date they are posted on our website.
      <br/><br/>
      By continuing to use our website after changes are posted you are accepting our new terms.html.`,
    },
    {
      title: "The Sofort24Kredit  service",
      html: `We provide an online credit broking service and can help you to search for certain products and services provided by our panel of lenders and credit brokers (“Our Partners”). We do not charge you a fee for using the Sofort24Kredit  service, but we do receive commission from Our Partners if they are able to provide you with the particular product or service you were looking for.
      <br/><br/>
      Whether or not Our Partners are able to provide you with the product or service you are looking for, is their responsibility and will depend on factors such as their assessment of your eligibility and credit worthiness by taking into account your credit history.
      <br/><br/>
      You are protected under the Consumer Credit Act 1974 (as amended) (“CCA”), and Our Partners are authorised and regulated by the Financial Conduct Authority.
      <br/><br/>
      To find out more information about how the CCA protects the rights of consumers contact the Trading Standards Institute www.gov.uk/find-local-trading-standards-office or the Citizens Advice Bureau www.citizensadvice.org.uk`,
    },
    {
      title: "Your use of our Website",
      html: `Our website is for your personal use only and you must not use it in any unlawful or fraudulent manner. You should only use our service if you a UK resident aged 18 years or over.
      <br/><br/>
      If you are using our website on behalf of another person, you are responsible for ensuring that they understand and agree to these terms.html. When you provide their information, you are confirming to us that you have their permission to do this.`,
    },
    {
      title: "Communication from or on behalf of Sofort24Kredit ",
      html: `We may contact you by telephone, SMS, email and post about our similar products and services unless you have told us that you do not want to be contacted in this way. (Please read our privacy policy for more information).
        <br/><br/>
      By using our service to find a financial service or product, we will endeavour to match you with one of Our Partners, and if successful you will be re-directed to their website to complete your application. Once re-directed, you should read their privacy policy to satisfy yourself how they will process your personal information (this might include for e.g. them contacting you by telephone, SMS, email, and/ post to provide you with that particular financial service or product.) We are not responsible for the way in which any of Our Partners may do this.`,
    },
    {
      title: "Communication from or on behalf of Sofort24Kredit ",
      html: `We do not carry out credit checks on you when you use our website to find a financial product or service.
      <br/><br/>
      However, Our Partners may use external credit reference agencies and credit scoring services to ascertain whether you are eligible for a particular product or service and that you meet their eligibility criteria.
      <br/><br/>
      Credit checks and credit reference agencies can also help prevent fraudulent applications. When you make an application, you agree to Our Partners using your personal information to prevent fraud and to verify your identity. This data may be used by a Credit Reference Agency or Fraud Agency and these agencies may keep a record of your information.
      <br/><br/>
      You are responsible for ensuring that the information you provide is true and accurate. Misleading information will be regarded as a breach of our terms and is likely to breach any agreement you enter into with Our Partners. Furthermore, misleading information may also result in the instigation of criminal procedures against you.`,
    },
    {
      title: "Intellectual Property Rights",
      html: `We are the owner or the licensee of all intellectual property rights in our website, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
      <br/><br/>
      You may print off one copy, and may download extracts, of any page(s) from our website for your personal reference and you may draw the attention of others to material posted on our website.
      <br/><br/>
      You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
      <br/><br/>
      Our status (and that of any identified contributors) as the authors of material on our website must always be acknowledged.
      <br/><br/>
      You must not use any part of the materials on our website for commercial purposes without obtaining a licence to do so from us or our licensors.
      <br/><br/>
      All rights in sofort24kredit.de are owned by us.`,
    },
    {
      title: "Third party websites and Links from our Website",
      html: `If you leave our website to complete an application for a product or service on the website of one of Our Partners, it is very important that you read their terms.html to make sure you understand and accept them.
      <br/><br/>
      Where our website contains links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them.
      `,
    },
    {
      title: "Information about you and your visits to our website",
      html: `We process information about you in accordance with our privacy policy. By using our website, you acknowledge that your information will be processed in that way, and you warrant that all data provided by you is accurate.
      <br/><br/>
      We aim to ensure that the material on our website is accurate and try to correct any errors or omissions as soon as we become aware of them. However, we cannot guarantee that our website is always accurate and error free. We therefore reserve the right to amend any information displayed at any time.
      `,
    },
    {
      title: "Financial Information",
      html: `The information on our website is not intended to be construed as financial or other advice. It is provided to help you find the product or service that you consider is the most appropriate for your circumstances. If you think you need financial advice, you should contact a registered and qualified Independent Financial Adviser (IFA).
      `,
    },
    {
      title: "Our Liablity",
      html: `We do not accept responsibility or liability for any loss or damage you may incur (whether directly or indirectly) in the following circumstances:
      <ul>
      <li>
      If the information you provide to us is incorrect, incomplete or misleading;
      </li>
      <li>
      If the product or service you apply for is not appropriate for you;
      </li>
      <li>
      As a result of any acts or omissions of Our Partners regarding the product or service you are applying for, and/or
      </li>
      <li>
      As a result of your use, or inability to use our website due to circumstances beyond our reasonable control.
      </li>
      </ul>
      This does not affect our liability for death or personal injury arising from our negligence, nor our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor any other liability which cannot be excluded or limited under applicable law including but not limited to the Financial Services and Markets Act 2000, as amended, ("FSMA") or any conduct of business rules developed pursuant to FSMA.
      `,
    },
    {
      title: "Viruses, Hacking and other Offences",
      html: `You must not misuse our website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our website, the server on which our website is stored, or any server, computer or database connected to our website. You must not attack our website via a denial-of-service attack or a distributed denial-of service attack.
      <br/><br/>
      By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our website will cease immediately.
      <br/><br/>
      We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our website or to your downloading of any material posted on it, or on any website linked to it.
      <br/><br/>
      You must not establish a link from any website that is not owned by you.
      <br/><br/>
      Our website must not be framed on any other site, nor may you create a link to any part of our website other than the home page. We reserve the right to withdraw linking permission without notice.
      <br/><br/>
      If you wish to make any use of material on our website other than that set out above, please contact us on our contact form by clicking here.`,
    },
    {
      title: "Complaints",
      html: `We aim to offer an excellent service to all our customers. If you feel that we have failed to provide our service or wish to make a formal complaint of any kind, please write to:
      <br/><br/>
      Compliance Manager<br/>
      Sofort24Kredit <br/>
      Digitonomy Limited,<br/>
      Steam Mill Business Centre,<br/>
      Steam Mill Street,<br/>
      Chester<br/>
      Cheshire<br/>
      CH3 5AN<br/>
      <br/><br/>
      A response will be provided promptly upon receipt of your letter of complaint.
      <br/><br/>
      If we do not resolve your complaint internally to your satisfaction, you may be able to refer your complaint to the Financial Ombudsman Service:
      <br/><br/>
      Financial Ombudsman Service,<br/>
      Exchange Tower,<br/>
      London,<br/>
      E14 9SR.<br/><br/>
      www.financial-ombudsman.org.uk`,
    },
    {
      title: "Applicable Law and Jurisdiction",
      html: `These terms.html and your access to and use of our website (including any dispute or claim arising out of or in connection with them), shall be governed by and interpreted in accordance with English law.
      <br/><br/>
      You agree to submit to the exclusive jurisdiction of the courts of England and Wales in connection with these terms.html and your access to and use of our website.`,
    },
    {
      title: "General",
      html: `This agreement is in English and all communications between you and us will be in English.
      <br/><br/>
      If any provision of these terms.html is found to be unlawful, invalid or unenforceable, that provision shall be deemed deleted from these terms.html and shall not affect the validity or enforceability of the remaining terms.html.
      <br/><br/>
      Our terms.html, privacy policy and cookie policy, constitute the entire agreement between you and us relating to your use of our website.
      <br/><br/>
      No failure or delay by us in exercising any of our rights under these terms.html shall constitute a waiver of that right nor will any single or partial exercise by us of any right preclude any further exercise of any right.`,
    },
    {
      title: "How to contact us",
      html: `If you have any questions about these terms.html, or would like to contact us for any other reason, you can contact us:
      <br/><br/>
      by post: Customer Services Manager, Digitonomy Limited, Steam Mill Business Centre, Steam Mill Street, Chester, Cheshire, CH3 5AN, or
      <br/><br/>
      by email: please click here to submit a contact form.`,
    },
  ],
}
